export type KeyEvent = {
  keyCode: KeyboardEvent['keyCode']
  charCode: KeyboardEvent['charCode']
  preventDefault: KeyboardEvent['preventDefault']
}
type Trapper = (
  event: KeyEvent,
  keyOrList: number | number[],
  block?: (event: KeyEvent) => void,
  otherwise?: (event: KeyEvent) => void
) => void

const trap: Trapper = (event, keyOrList, block, otherwise) => {
  const keysSearching: number[] = Array.isArray(keyOrList) ? keyOrList : [keyOrList]
  if ([event.keyCode, event.charCode].some((code) => keysSearching.includes(code))) {
    event.preventDefault()
    if (typeof block === 'function') {
      block(event)
    }
  } else if (typeof otherwise === 'function') {
    otherwise(event)
  }
}

export default {
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
  DOWN: 40,
  trap,
}
