import React, {
  FormEventHandler, useEffect, useRef, useState,
} from 'react'
import Keys from '@/helpers/Keys'
import { error, log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

import dynamic from 'next/dynamic'
import { sendDataToBloomreach } from '@/components/ScriptIntegrations/BloomreachEvents'
import { getBloomreachConfig } from '@/helpers/getBloomreachConfig'
import { xssEscape } from '../../../../helpers/getQueryWithValidCharacters'

const Link = dynamic(import('@csc/dls/Link'))
const Text = dynamic(import('@csc/dls/Text'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const SearchIcon = dynamic(import('@csc/dls/Icons/SearchIcon'))

const AlphanumericInput = dynamic(import('@csc/dls/AlphanumericInput'))

const CategoryResult = dynamic(import('./CategoryResult'))
const ProductResult = dynamic(import('./ProductResult'))

type SearchResponse = {
  query: string,
  response: SearchResult
}

type CustomUrl = {
  url: string
}

type SearchCategory = {
  match: string,
  source: {
    data: {
      custom_url: CustomUrl
      id: number,
      name: string
    }
  }
}

type SearchProductImage = {
  url_square: string
  url_standard: string
  url_thumbnail: string
  url_zoom: string
}

type SearchProduct = {
  highlight: unknown[],
  match: string,
  source: {
    data: {
      id: number
      custom_url: CustomUrl
      images: SearchProductImage[],
      name: string,
    }
  }
}

type SearchResult = {
  inspiration: unknown[],
  inspiration_static: unknown[],
  category: Array<SearchCategory>,
  product: Array<SearchProduct>,
  redirectUrl: string,
  redirectText
}

const upcase = (s: string) => s.slice(0, 1).toUpperCase() + (s?.slice(1) || '').toLowerCase()

const disambiguatedName = ({ name, url }: { name: string, url: string }) => {
  const tokenLength = name.split(' ').length
  const partsFromUrl = url.replace(/^\/[a-z]-\d+-/, '').split('-')
  return `${name} (${partsFromUrl.slice(0, partsFromUrl.length - tokenLength).map(upcase).join(' ')})`
}

const disambiguate = (categoryEntries: SearchCategory[]) => {
  const ZERO_HASH: { [key: string]: SearchCategory[] } = {}
  const nameHashTable = categoryEntries.reduce((hash, entry) => {
    // eslint-disable-next-line no-param-reassign
    hash[entry.source.data.name] = hash[entry.source.data.name] || []
    hash[entry.source.data.name].push(entry)
    return hash
  }, ZERO_HASH)
  return categoryEntries.map((entry) => {
    const name = nameHashTable[entry.source.data.name].length > 1
      ? disambiguatedName({
        name: entry.source.data.name,
        url: entry.source.data.custom_url.url,
      })
      : entry.source.data.name
    return { ...entry, name }
  })
}

const renderableResults = ({
  category,
  product,
  redirectUrl,
  ...rest
}: SearchResult): SearchResult => ({
  ...rest,
  category: disambiguate(category),
  product,
  redirectUrl,
})

type AnyResultsInput = null | {
  category?: unknown[],
  inspiration?: unknown[],
  inspiration_static?: unknown[],
  product?: unknown[],
}
const anyResults = (result: AnyResultsInput) => [
  result?.category,
  result?.inspiration,
  result?.inspiration_static,
  result?.product,
].some((piece) => piece?.length)

type SearchBoxProps = {
  id: string,
  query: string,
  setQuery: React.Dispatch<React.SetStateAction<string>>
  onFocus: React.FocusEventHandler<HTMLInputElement>
  onBlur: React.FocusEventHandler<HTMLInputElement>
}

const SearchBox: React.FC<SearchBoxProps> = ({
  id = 'SearchBox',
  query,
  setQuery,
  onFocus,
  onBlur,
}) => {
  const [searchResult, setSearchResult] = useState<null | SearchResult>(null)
  const [shouldBeVisible, setShouldBeVisible] = useState(false)
  const searchNodeRef = useRef<HTMLDivElement>(null)
  const [mostRecentResponse, setMostRecentResponse] = useState<null | SearchResponse>(null)

  const onKeyUp = ({ keyCode }) => {
    if (keyCode === Keys.ESCAPE) {
      setShouldBeVisible(false)
    }
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const escapedQuery = xssEscape(query)
    const encoded = encodeURIComponent(escapedQuery)
    sendDataToBloomreach('SearchEvent', escapedQuery).catch(error)
    if (query) {
      window.location.href = `/search.php?query=${encoded}`
    }
  }

  useEffect(() => {
    if (mostRecentResponse?.query === query) {
      const renderable = renderableResults(mostRecentResponse.response)
      setSearchResult({
        ...renderable,
        redirectText: mostRecentResponse.query,
      })
      setShouldBeVisible(anyResults(renderable))
      try {
        const content_ids = mostRecentResponse.response.product.map(({ match }) => `${match}`.split('~')[1] || '')
        const contents = content_ids.map((sku) => ({ id: sku, quantity: 1, item_price: null }))
        allPromisesWithRetries(() => [import('@/services/Facebook')])
          .then(([{ default: Facebook }]) => Facebook.search({
            search_string: mostRecentResponse?.query,
            content_ids,
            contents,
          })).catch(error)
      } catch (err) {
        error('Facebook Pixel Error Failed to send Search event', err)
      }
    }
  }, [mostRecentResponse, query])

  useEffect(() => {
    const update = async () => {
      if (query) {
        const [{ default: Search }] = await allPromisesWithRetries(() => [import('@/services/Search')])
        const bloomreachConfig = getBloomreachConfig()
        const response: SearchResult = await Search.suggest(
          xssEscape(query.trim()),
          bloomreachConfig,
        )
        setMostRecentResponse({ query, response })
      }
    }
    setShouldBeVisible(!!query)
    const debounceId = setTimeout(() => {
      update().catch(error)
    }, 500)
    return () => {
      clearTimeout(debounceId)
    }
  }, [query, setMostRecentResponse, setShouldBeVisible])

  useEffect(() => {
    const handleClick = ({ target }) => {
      try {
        const shouldBe = searchNodeRef?.current?.contains(target)
        setShouldBeVisible(!!shouldBe)
      } catch (err) {
        error('SearchBar failed', err)
      }
    }
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchNodeRef?.current, setShouldBeVisible])

  const seeAllUrl = `/search.php?query=${encodeURIComponent(query)}`

  return (
    <div ref={searchNodeRef} className="relative search-input-parent">
      <form data-testid="quick-search-form" className="relative" action="/search.php" onSubmit={handleSubmit}>
        <AlphanumericInput
          className="SearchBarInput"
          type="text"
          id={id}
          data-search-quick
          data-error-message="Search field cannot be empty."
          placeholder="Search"
          label="Search"
          autoComplete="off"
          value={query}
          onKeyUp={onKeyUp}
          onChange={({ target: { value } }) => {
            log(`setQuery: ${value}`)
            setQuery(value)
          }}
          onFocus={(e) => {
            onFocus(e)
            setShouldBeVisible(anyResults(searchResult))
          }}
          onBlur={onBlur}
          required
        />
        <button className="absolute right-0 transform top-1/2 -translate-y-1/2 mr-2" type="submit" aria-label="submit">
          <IconRenderer IconComponent={SearchIcon} size="sm" />
        </button>
      </form>
      {shouldBeVisible && searchResult && (
        <div id="TypeAheadResult" className="top-full items-center align-baseline m-0 p-0 border-0">
          <div className="typehead z-110 w-full border-black border absolute top-full">
            <ul>
              {searchResult.redirectUrl ? (
                <li>
                  <ul>
                    <li>
                      <Link href={searchResult.redirectUrl}>
                        <Text size="lg" bold className="capitalize mb-1">
                          {searchResult.redirectText}
                        </Text>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {searchResult.category?.length
                ? (
                  <li>
                    Categories
                    <ul>
                      {searchResult.category.slice(0, 5).map((category) => (
                        <CategoryResult
                          key={category.source.data.id}
                          category={category}
                          query={query}
                        />
                      ))}
                    </ul>
                  </li>
                )
                : null}
              {searchResult?.product?.length
                ? (
                  <li>
                    <div className="see_all">
                      <a href={seeAllUrl}>See All</a>
                    </div>
                    Products
                    <ul>
                      {searchResult.product.slice(0, 7).map((product) => (
                        <ProductResult
                          key={product.source.data.id}
                          product={product}
                          query={query}
                        />
                      ))}
                    </ul>
                  </li>
                )
                : null}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchBox
